// banner

.home-banner-section {
	margin-top: -80px;
	height: 100%;
	max-height: 800px;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 180px 0 100px 0;
	background-color: #000;
	position: relative;

	.banner-background {
		position: absolute;
		background-color: #000;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.container {
		position: relative;
		z-index: 100;
	}

	&::after {
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.3;
		background: linear-gradient(122deg,
				#0091da -11.62%,
				rgba(0, 145, 218, 0.88) 8.53%,
				rgba(69, 168, 104, 0) 69.21%,
				#84bd00 100%);
		display: block;
		z-index: 2;
	}

	.banner-content {
		h1 {
			color: #fff;
			font-size: 48px;
			font-style: normal;
			font-weight: 800;
			line-height: normal;
			margin-bottom: 24px;
		}

		p {
			color: #fff;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 1.5;
			margin-bottom: 20px;
		}

		ul.banner-tags {
			display: inline-flex;
			gap: 10px;
			padding: 0;
			margin: 0;
			flex-wrap: wrap;
			margin-bottom: 28px;

			li {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				display: inline-flex;
				padding: 4px 12px;
				justify-content: center;
				align-items: center;
				border-radius: 24px;
				background-color: rgba(255, 255, 255, 0.2);
				color: #fff;
			}
		}
	}

	.banner-links {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		ul.page-link {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			gap: 10px;
			padding: 0;
			margin: 0;

			li {
				display: flex;

				&:nth-child(2) {
					color: red;

					span {
						min-width: 300px;
					}
				}

				&:nth-of-type(4) {
					span {
						min-width: 300px;
					}
				}
			}
		}
	}

	.bannerlink {
		color: #040405;
		text-align: right;
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		padding: 16px 20px;
		padding-left: 30px;
		background-color: #fff;
		width: auto;
		border-radius: 42px 12px 12px 42px;
		min-width: 230px;
		position: relative;
		z-index: 100;
        transition: all 0.4s;
		&:hover {
			margin-right: 20px;
		}
	}

	.banner-txt-link {
		color: #fff;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		/* 27px */
		text-decoration-line: underline !important;
	}
}

.technology-new-section {
	.contact-right-form {
		h3 {
			font-size: 20px;
			margin-bottom: 24px;
		}
	}

	.contact-right-form .form-group {
		margin-bottom: 36px;
	}

	.contact-right-form .form-group .form-control {
		padding: 13px 16px;
		height: unset;
		min-height: unset;
		font-size: 14px;
		border-radius: 10px;
	}

	.contact-right-form .form-floating>label {
		padding: 12px;
	}

	.contact-right-form .budget-btn {
		margin-top: 0;
	}

	.contact-right-form .sign-nda {
		margin-top: 16px;
	}
}

@media only screen and (max-width: 1400px) and (min-width: 1025px) {
	.container {
		max-width: 1000px !important;
	}

	.home-banner-section {
		padding-top: 160px !important;
	}

	.home-banner-section .banner-content h1 {
		font-size: 36px !important;
		font-weight: 700;
	}

	.home-banner-section .bannerlink {
		font-size: 14px !important;
		white-space: nowrap;
		padding: 14px 12px;
	}

	.home-banner-section .bannerlink {
		min-width: 180px;
	}

	.home-banner-section .banner-links ul.page-link li:nth-of-type(4) span,
	.home-banner-section .banner-links ul.page-link li:nth-of-type(2) span {
		min-width: 225px;
		max-width: 225px;
	}

	.home-banner-section .banner-links ul.page-link li:nth-of-type(3) {
		min-width: 266px;
	}

	.bannerlink:hover {
		margin: 0 !important;
	}

	.banner-links {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;

		ul {
			li {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: 100%;
			}
		}
	}
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
	.home-banner-section .banner-content h1 {
		font-size: 36px !important;
	}

	.home-banner-section .bannerlink {
		font-size: 14px !important;
	}

	.home-banner-section .bannerlink {
		min-width: 180px;
	}

	.home-banner-section .banner-links ul.page-link li:nth-of-type(4) span,
	.home-banner-section .banner-links ul.page-link li:nth-of-type(2) span {
		min-width: 235px;
	}
}

@media (max-width: 1400px) and (min-width: 1025px) {
	.home-banner-section .bannerlink {
		font-size: 15px;
	}
}

@media (max-width: 1024px) {
	.technology-new-section .contact-right-form .form-group {
		margin-bottom: 24px;
	}

	.technology-new-section .contact-right-form h3 {
		font-size: 18px;
		font-weight: 600;
		line-height: 26px;
	}

	.home-banner-section {
		padding-top: 160px !important;
	}

	.home-banner-section .banner-content h1 {
		font-size: 36px !important;
		font-weight: 700;
	}

	.home-banner-section .bannerlink {
		font-size: 14px !important;
		white-space: nowrap;
		padding: 14px 12px;
	}

	.home-banner-section .bannerlink {
		min-width: 180px;
	}

	.home-banner-section .banner-links ul.page-link li:nth-of-type(4) span,
	.home-banner-section .banner-links ul.page-link li:nth-of-type(2) span {
		min-width: 225px;
		max-width: 225px;
	}

	.home-banner-section .banner-links ul.page-link li:nth-of-type(3) {
		min-width: 266px;
	}

	.bannerlink:hover {
		margin: 0 !important;
	}

	.banner-links {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;

		ul {
			li {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: 100%;
			}
		}
	}
}

@media (max-width: 992px) {
	.home-banner-section {
		max-height: unset;
		padding: 120px 0 60px 0 !important;

		.banner-content {
			h1 {
				font-size: 28px !important;
			}

			p {
				font-size: 14px;
			}
		}

		.bannerlink {
			padding: 8px 12px;
			font-size: 12px;
			white-space: nowrap;
			min-width: 170px;
		}
	}

	.banner-links {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;

		ul {
			li {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: 100%;
			}
		}
	}

	.home-banner-section .banner-links ul.page-link li:nth-of-type(4) a,
	.home-banner-section .banner-links ul.page-link li:nth-of-type(2) a {
		min-width: 200px;
	}

	.home-banner-section .banner-links ul.page-link li:nth-of-type(3) {
		min-width: 230px;
	}
}

@media (max-width: 767px) {
	.bannerlink {
		padding: 8px 12px;
		font-size: 14px;
		min-width: unset !important;
		border-radius: 12px 42px 42px 12px;
	}

	.banner-links .page-link {
		flex-wrap: wrap;
		flex-direction: row !important;
	}

	.home-banner-section .banner-txt-link {
		font-size: 13px;
	}

	.home-banner-section .banner-links ul.page-link li {
		width: 100%;
	}

	.home-banner-section .bannerlink {
		padding: 9px 20px;
	}

	.home-banner-section .banner-content ul.banner-tags li {
		font-size: 12px;
	}

	// technologies banner css
	.technology-new-section {
		.small-reverse-row {
			flex-direction: column-reverse;
		}
	}

	.technology-new-section .contact-right-form h3 {
		font-size: 16px;
		margin-bottom: 16px;
	}

	.technology-new-section .contact-right-form .form-group {
		margin-bottom: 16px;
	}

	.technology-new-section .contact-right-form .sign-nda {
		margin-top: 0;
		margin-bottom: 16px;
	}

	.technology-new-section.contact-new-section .contact-right-form {
		margin-bottom: 32px;
	}

	.technology-new-section.home-banner-section .banner-content ul.banner-tags {
		margin-bottom: 0;
	}

	.banner-links ul li {
		justify-content: flex-start;
	}

	.home-banner-section .bannerlink {
		border-radius: 12px 42px 42px 12px;
	}
}

// banner min height css start
@media only screen and (min-width: 1400px) {
	.home-banner-section {
		min-height: 800px;
	}
}
// banner min height css end
